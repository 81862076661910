import { z } from "zod";

import OrgUserRefByIdSchema from "@/areas/directory/schemas/OrgUserRefByIdSchema";
import UserRoleSchema from "@/areas/directory/schemas/UserRoleSchema";

const InviteUserSchema = z
  .object({
    user: OrgUserRefByIdSchema,
    role: UserRoleSchema,
  })
  .strict();

const InviteUsersSchema = z
  .object({
    invitations: z.array(InviteUserSchema),
  })
  .strict();

export default InviteUsersSchema;
