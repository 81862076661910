import { z } from "zod";

const domainSchema = z.object({
  id: z.string().array().nullable(),
});

const querySchema = z.object({
  domain: domainSchema,
});

const BrowseDomainControlsSchema = z
  .object({
    search: z.string(),
    query: querySchema,
    page: z.number().min(1, { message: "Page is out of bounds" }),
  })
  .strict();

export default BrowseDomainControlsSchema;
