import { makeObservable, observable, flow, computed, action } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseRolesSchema from "@/areas/settings/schemas/BrowseRolesSchema";
import CreateRoleStore from "@/areas/settings/stores/CreateRoleStore";

export default class BrowseRolesStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  roles = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      roles: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;

    this.createRoleStore = new CreateRoleStore(this);
  }
  get processing() {
    return this.loading || this.createRoleStore.processing;
  }
  get actions() {
    const list = [];

    list.push({
      id: "settings:roles",
      label: "New Role",
      onClick: () => this.createRoleStore.show(),
      icon: Add,
      location: "primary",
      processing: this.createRoleStore.processing,
      disabled: this.createRoleStore.disabled,
    });
    
    
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(role) {
    const index = this.roles.findIndex((u) => u.id === role.id);
    if (index !== -1) {
      this.roles.splice(index, 1, role);
      this.roles = [...this.roles];
    }
  }

  *load(query, options) {
    const parseResult = BrowseRolesSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.roles = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forRoles(), {
        params: query,
      });

      if (response.status == 200) {
        this.roles = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }
    } catch (e) {
      toast.error("Error loading roles", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
