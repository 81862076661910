import { z } from "zod";

const BrowseDeploymentsQueryControlSchema = z.object({
  id: z.string().array().nullable(),
});

const BrowseDeploymentsQueryTargetSchema = z.object({
  id: z.string().array().nullable(),
});

const BrowseDeploymentsQuerySchema = z.object({
  control: BrowseDeploymentsQueryControlSchema.optional(),
  target: BrowseDeploymentsQueryTargetSchema.optional(),
  targetLevel: z.string().array().optional(),
  complianceLevel: z.string().array().optional(),
});

const BrowseDeploymentsSchema = z.object({
  search: z.string(),
  page: z.number().min(1, { message: "Page is out of bounds" }),
  query: BrowseDeploymentsQuerySchema,
  sort: z.array(z.object({ property: z.string(), asc: z.boolean() })),
});

export default BrowseDeploymentsSchema;
