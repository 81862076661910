import { makeObservable, observable, flow, action, computed } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import UpdateModelSchema from "@/areas/settings/schemas/UpdateModelSchema";

export default class UpdateModelStore {
  visible = false;
  processing = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      processing: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
      canUpdate: computed,
    });

    this.parentStore = parentStore;
  }

  get canUpdate() {
    return this.parentStore.parentStore.parentStore.authenticationStore.isAdmin;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    try {
      this.processing = true;

      const parseResult = UpdateModelSchema.safeParse(data);
      if (!parseResult.success) {
        return parseResult;
      }

      const response = yield api.put(ApiRoutes.forModel(data.id), data);

      this.visible = false;
      toast.success("Model updated successfully");
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while updating model", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.processing = false;
    }
  }
}
