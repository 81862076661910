import axios from "axios";
import axiosRetry from "axios-retry";
import globalRouter from "@/globalRouter";

export const api = axios.create({
  baseURL: "/api",
  timeout: 15000,
  headers: { "X-CSRF": "1" },
});

axiosRetry(api, { retries: 3 });

export const assets = axios.create({
  timeout: 120000,
  headers: { "X-CSRF": "1" },
});

axiosRetry(assets, { retries: 3 });

export class ServiceError {
  constructor(params) {
    this.title = params.title;
    this.name = "ServiceError";
    this.type = params.type;
    this.traceId = params.traceId;
    this.status = params.status;
    this.code = params.code;
    this.errors = params.errors;
  }
}

export class ServiceBase {
  constructor(authProvider) {
    this.authProvider = authProvider;
  }

  async handleErrors(response) {
    if (!response.ok) {
      const data = await response.json();
      const error = new ServiceError(data);
      throw error;
    }
    return response;
  }

  async getBlob(url, options = null) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Access-Control-Allow-Origin": window.location.origin,
      },
      ...options,
    })
      .then(this.handleErrors)
      .then((r) => r.blob());
  }

  /**
   * Get
   */
  async get(url) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => {
        if (r.status === 204) {
          return null;
        }
        return r.json();
      });
  }

  async postForm(url, data) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => r.json());
  }

  /**
   * Post
   */
  async post(url, data) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => r.json());
  }

  async putForm(url, data) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => r.json());
  }

  /**
   * Put
   */
  async put(url, data) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => r.json());
  }

  /**
   * Delete
   */
  async delete(url, data) {
    const token = await this.authProvider.getAccessToken();

    return await fetch(url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      },
    })
      .then(this.handleErrors)
      .then((r) => r.json());
  }
}
