import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ControlMaturityDeficitReportStore {
  loading = false;
  config = {};
  data = [
    {
      id: 1,
      name: "Governance",
      maturityDiff: 2,
      children: [
        {
          id: 1.1,
          name: "Inventory and Control of Hardware Assets",
          maturityDiff: 2,
        },
        {
          id: 1.2,
          name: "Inventory and Control of Software Assets",
          maturityDiff: 1,
        },
        { id: 1.3, name: "Secure Configuration for Hardware and Software", maturityDiff: -2 },
        {
          id: 1.4,
          name: "Email and Web Browser Protections",
          maturityDiff: 0,
        },
      ],
    },
    {
      id: 2,
      name: "Vulnerability and Threat Protection",
      maturityDiff: -1,
      children: [
        { id: 2.1, name: "Information classification", maturityDiff: 4 },
        { id: 2.2, name: "Media handling", maturityDiff: 2 },
        { id: 2.3, name: "Business requirements of access control", maturityDiff: 1 },
        { id: 2.4, name: "User access management", maturityDiff: -4 },
      ],
    },
    {
      id: 3,
      name: "Identity",
      maturityDiff: 0,
      children: [
        { id: 3.1, name: "System and application access control", maturityDiff: -4 },
        { id: 3.2, name: "Maintenance, Monitoring and Analysis of Audit Logs", maturityDiff: 1 },
        { id: 3.3, name: "Information systems audit considerations", maturityDiff: 1 },
        { id: 3.4, name: "Incident Response and Management", maturityDiff: 0 },
        { id: 3.5, name: "Security in development and support processes", maturityDiff: -4 },
      ],
    },
    {
      id: 4,
      name: "Operation",
      maturityDiff: -1,
      children: [
        { id: 4.1, name: "Compliance with legal and contractual requirements", maturityDiff: -4 },
        { id: 4.2, name: "Information security reviews", maturityDiff: 0 },
        { id: 4.3, name: "Management of information security incidents & improvements", maturityDiff: 4 },
      ],
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      data: observable,
      config: observable,
      actions: computed,
      setSearchText: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  *load(query, options) {}
}
