import { ApiRoutes } from "@/lib/routes";

import BrowseControlsStore from "@/areas/compliance/stores/BrowseControlsStore";
import BrowseDomainsStore from "@/areas/compliance/stores/BrowseDomainsStore";
import BrowseTargetsStore from "@/areas/compliance/stores/BrowseTargetsStore";
import BrowseDeploymentsStore from "@/areas/compliance/stores/BrowseDeploymentsStore";
import CreateControlStore from "@/areas/compliance/stores/CreateControlStore";
import CreateDomainStore from "@/areas/compliance/stores/CreateDomainStore";
import CreateTargetStore from "@/areas/compliance/stores/CreateTargetStore";
import BrowseTargetRolesStore from "@/areas/subscriptions/stores/BrowseTargetRolesStore";

import CreateControlRequirementStore from "@/areas/controls/stores/CreateControlRequirement";
import ControlHistoryViewerStore from "@/areas/controls/stores/ControlHistoryViewerPage";
import UpdateCurrentActivityStore from "@/areas/controls/stores/UpdateCurrentActivityStore";
import UpdateControlRequirementStore from "@/areas/controls/stores/UpdateControlRequirementStore";
import CreateControlSubdomainStore from "@/areas/controls/stores/CreateControlSubdomainStore";
import CreateSubdomainStore from "@/areas/controls/stores/CreateSubdomainStore";
import BrowseSubdomainStore from "@/areas/controls/stores/BrowseSubdomainStore";
import BrowseDomainControlsStore from "@/areas/controls/stores/BrowseDomainControlsStore";

export default class ComplianceRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseControlsStore = new BrowseControlsStore(this);
    this.browseDomainsStore = new BrowseDomainsStore(this);
    this.browseTargetsStore = new BrowseTargetsStore(this);
    this.browseDeploymentsStore = new BrowseDeploymentsStore(this);
    this.createDomainStore = new CreateDomainStore(this);
    this.createControlStore = new CreateControlStore(this);
    this.createTargetStore = new CreateTargetStore(this);
    // this.createControlRequirementStore = new CreateControlRequirementStore(this);
    // this.controlHistoryViewerPage = new ControlHistoryViewerStore(this);
    // this.updateCurrentActivityStore = new UpdateCurrentActivityStore(this);
    // this.createSubdomainStore = new CreateSubdomainStore(this);
    // this.updateControlRequirementStore = new UpdateControlRequirementStore(this);
    // this.createControlSubdomainStore = new CreateControlSubdomainStore(this);
    // this.browseSubdomainStore = new BrowseSubdomainStore(this);
    // this.browseDomainControlsStore = new BrowseDomainControlsStore(this);

    this.browseRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forComplianceRoles());
  }
}
