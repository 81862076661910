import { makeObservable, observable, flow, computed } from "mobx";
import { Edit, Add } from "@/components/icons/IconMap";

import BrowseAuditsSchema from "@/areas/audits/schemas/BrowseAuditsSchema";

export default class BrowseAuditsStore {
  loading = false;
  pagination = null;
  audits = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      audits: observable,
      actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "audit:addAudit",
      label: "New Audit",
      onClick: () => this.parentStore.createAuditStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createAuditStore.processing,
      disabled: this.parentStore.createAuditStore.processing,
    });
    // list.push({
    //   id: "audit:changeScope",
    //   label: "Change Scope",
    //   onClick: () => this.parentStore.createAuditStore.show(),
    //   icon: Edit,
    //   location: "secondary",
    //   processing: this.parentStore.createAuditStore.processing,
    //   disabled: this.parentStore.createAuditStore.processing,
    // });

    return list;
  }

  consume(audit) {
    const index = this.audits.findIndex((e) => e.id === audit.id);
    if (index !== -1) {
      this.audits.splice(index, 1, audit);
      this.audits = [...this.audits];
    }
  }

  *load(query, options) {
    const parseResult = BrowseAuditsSchema.safeParse(query);
    if (!parseResult.success) {
      console.log("false parse");
      return parseResult;
    }

    try {
      // this.loading = true;
      // const response = yield api.get(ApiRoutes.forModels(), {
      //   params: query,
      // });
      // if (response.status == 200) {
      //   this.audits = response.data.items;
      //   this.pagination = {
      //     page: response.data.page,
      //     totalItems: response.data.totalItems,
      //     totalPages: response.data.totalPages,
      //     pageSize: response.data.pageSize,
      //   };
      //   return { success: true, data: response.data };
      // }
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
