import * as React from "react";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

export const useTheme = () => {
  const [theme, setTheme] = React.useState(() => {
    if (localStorage.theme === "dark") {
      return "dark";
    } else if (localStorage.theme === "light") {
      return "light";
    }
    return "system";
  });

  const isSystemDark = useMediaQuery("(prefers-color-scheme: dark)");

  React.useEffect(() => {
    switch (theme) {
      case "dark":
        localStorage.theme = "dark";
        document.documentElement.classList.add("dark");
        break;
      case "light":
        localStorage.theme = "light";
        document.documentElement.classList.remove("dark");
        break;
      default:
        localStorage.removeItem("theme");
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }
        break;
    }
  }, [theme, isSystemDark]);

  return { theme, setTheme };
};
