import { ArrowUpRight } from "lucide-react";
import { makeObservable, observable, flow, computed, action } from "mobx";

export default class RiskOwnershipReportStore {
  loading = false;
  pagination = {};
  config = {};
  query = { search: "", page: 1 };

  riskCoordinators = [
    {
      coordinator: {
        id: "1",
        name: "Alex Malbon",
        email: "alex.malbon@zerod.ai",
        img: "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 1, major: 1, moderate: 0, minor: 2, total: 4 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "2",
        name: "Zubair Ahmed",
        email: "zubair.ahmed@zerod.ai",
        img: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 4, major: 2, moderate: 2, minor: 2, total: 10 },

      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "3",
        name: "Tim Cook",
        email: "tim.cook@zerod.ai",
        img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 3, major: 1, moderate: 0, minor: 0, total: 4 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
  ];
  riskAccountables = [
    {
      coordinator: {
        id: "1",
        name: "Alex Malbon",
        email: "alex.malbon@zerod.ai",
        img: "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 2, major: 2, moderate: 1, minor: 1, total: 6 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "2",
        name: "Zubair Ahmed",
        email: "zubair.ahmed@zerod.ai",
        img: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 1, major: 1, moderate: 1, minor: 1, total: 4 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "3",
        name: "Tim Cook",
        email: "tim.cook@zerod.ai",
        img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 2, major: 1, moderate: 3, minor: 3, total: 9 },

      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
  ];

  riskResponsibles = [
    {
      coordinator: {
        id: "1",
        name: "Alex Malbon",
        email: "alex.malbon@zerod.ai",
        img: "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 2, major: 3, moderate: 0, minor: 2, total: 7 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "2",
        name: "Zubair Ahmed",
        email: "zubair.ahmed@zerod.ai",
        img: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 4, major: 2, moderate: 2, minor: 2, total: 10 },
      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
    {
      coordinator: {
        id: "3",
        name: "Tim Cook",
        email: "tim.cook@zerod.ai",
        img: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      numberOfRisks: { id: 1, critical: 2, major: 1, moderate: 3, minor: 3, total: 9 },

      overdueRisks: 2,
      residual: 23,
      target: 17,
      residualColor: "bg-red-500 ",
      targetColor: "bg-orange-500",
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      riskCoordinators: observable,
      riskAccountables: observable,
      config: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [
      {
        id: "report:viewAll",
        label: "View all",
        onClick: () => console.log("clicked"),
        icon: ArrowUpRight,
        variant: "default",
        processing: false,
        disabled: false,
      },
    ];
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(riskType) {
    const index = this.report.findIndex((u) => u.id === riskType.id);
    if (index !== -1) {
      this.report.splice(index, 1, riskType);
      this.report = [...this.report];
    }
  }

  consume(riskType) {
    const index = this.fields.findIndex((u) => u.id === riskType.id);
    if (index !== -1) {
      this.fields.splice(index, 1, riskType);
      this.fields = [...this.fields];
    }
  }

  *load(query, options) {}
}
