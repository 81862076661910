import { z } from "zod";

const UpdateCurrentActivitySchema = z
  .object({
    fileUpload: z.instanceof(File).optional(),
    description: z.string().optional(),
    activityProgress: z.number().optional(),
  })
  .strict();

export default UpdateCurrentActivitySchema;
