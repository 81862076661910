import { z } from "zod";

const CreateAuditSchema = z
  .object({
    title: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must be less than 400 characters." }),
   
  })
  .strict();

export default CreateAuditSchema;
