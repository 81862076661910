import { makeAutoObservable } from "mobx";

import { TenantService } from "@/areas/tenants/services/TenantService";
import { TenantInvitationStore } from "@/areas/tenants/stores/TenantInvitationStore";
import { TenantRedeemStore } from "@/areas/tenants/stores/TenantRedeemStore";
import { TenantBrowseStore } from "@/areas/tenants/stores/TenantBrowseStore";

export class TenantStore {
  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
    this.tenantService = new TenantService(rootStore.msalAuthProvider);
    this.invitationStore = new TenantInvitationStore(this);
    this.redeemStore = new TenantRedeemStore(this);
    this.browseStore = new TenantBrowseStore(this);
  }
}
