import { makeObservable, observable, flow, computed } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Add } from "@/components/icons/IconMap";

import BrowseSubdomainSchema from "@/areas/controls/schemas/BrowseSubdomainSchema";

export default class BrowseSubdomainStore {
  loading = false;
  pagination = null;
  subdomains = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      subdomains: observable,
      // actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  //   get actions() {
  //     const list = [];

  //     list.push({
  //       id: "domain:addControl",
  //       label: "Add new domain",
  //       onClick: () => this.parentStore.createDomainStore.show(),
  //       icon: Add,
  //       location: "primary",
  //       processing: this.parentStore.createDomainStore.processing,
  //       disabled: this.parentStore.createDomainStore.processing,
  //     });

  //     return list;
  //   }

  consume(subdomain) {
    console.log("consume method", subdomain);
    const index = this.subdomains.findIndex((e) => e.id === subdomain.id);
    if (index !== -1) {
      this.subdomains.splice(index, 1, subdomain);
      this.subdomains = [...this.subdomains];
      console.log(this.subdomains);
    }
  }

  *load(query, options) {
    console.log("inside load", query);
    const parseResult = BrowseSubdomainSchema.safeParse(query);
    console.log("parseResult:", parseResult);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      this.loading = true;
      const response = yield api.post(ApiRoutes.forSubdomains(), query);

      console.log(response);
      if (response.status == 200) {
        this.subdomains = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      }
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
