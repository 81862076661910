import { ApiRoutes } from "@/lib/routes";

import BrowseReportsStore from "@/areas/analytics/stores/BrowseReportsStore";
import CreateReportStore from "@/areas/analytics/stores/CreateReportStore";
import BrowseTargetRolesStore from "@/areas/subscriptions/stores/BrowseTargetRolesStore";

export default class AnalyticsRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseReportsStore = new BrowseReportsStore(this);
    this.createReportStore = new CreateReportStore(this);

    this.browseRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forAnalyticsRoles());
  }
}
