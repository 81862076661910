import RiskResidualVsTargetTrendReportStore from "@/areas/reports/stores/RiskResidualVsTargetTrendReportStore";
import RiskTreatmentReportStore from "@/areas/reports/stores/RiskTreatmentReportStore";
import ControlFunctionReportStore from "@/areas/reports/stores/ControlFunctionReportStore";
import ControlImpactReportStore from "@/areas/reports/stores/ControlImpactReportStore";
import ComplianceMaturityTrendStore from "@/areas/reports/stores/ComplianceMaturityTrendStore";

import RiskOwnershipReportStore from "@/areas/reports/stores/RiskOwnershipReportStore";
import ExecutiveRiskReportStore from "@/areas/reports/stores/ExecutiveRiskReportStore";
import ControlComplianceReportStore from "@/areas/reports/stores/ControlComplianceReportStore";
import ControlMaturityDeficitReportStore from "@/areas/reports/stores/ControlMaturityDeficitReportStore";

export default class ReportRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.riskResidualVsTargetTrendReportStore = new RiskResidualVsTargetTrendReportStore(this);
    this.riskTreatmentReportStore = new RiskTreatmentReportStore(this);
    this.controlFunctionReportStore = new ControlFunctionReportStore(this);
    this.controlImpactReportStore = new ControlImpactReportStore(this);
    this.complianceMaturityTrendStore = new ComplianceMaturityTrendStore(this);
    this.executiveRiskReportStore = new ExecutiveRiskReportStore(this);
    this.riskOwnershipReportStore = new RiskOwnershipReportStore(this);
    this.controlComplianceReportStore = new ControlComplianceReportStore(this);
    this.controlMaturityDeficitReportStore = new ControlMaturityDeficitReportStore(this);
  }
}
