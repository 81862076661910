import { makeObservable, observable, flow, computed } from "mobx";
import { toast } from "sonner";

import { api } from "@/lib/services";

import BrowseTargetFieldsSchema from "@/areas/fields/schemas/BrowseTargetFieldsSchema";

export default class BrowseTargetFieldsStore {
  loading = false;
  fields = [];

  constructor(parentStore, apiPrefix) {
    makeObservable(this, {
      loading: observable,
      fields: observable,
      load: flow,
      filterFields: computed,
    });

    this.parentStore = parentStore;
    this.apiPrefix = apiPrefix;
  }

  get filterFields() {
    return this.fields.filter((x) => x.fieldType === "lookup");
  }

  *load(query, options) {
    const parseResult = BrowseTargetFieldsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      this.loading = true;
      const response = yield api.get(`${this.apiPrefix}`, {
        params: { search: query.search ?? "", page: query.page ?? 1, query: { type: ["lookup"] } },
      });
      this.fields = response.data.items;
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while loading target fields", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
