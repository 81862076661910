import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ControlFunctionReportStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  config = {};
   detectiveData = [
    {
      name: "Compliance Level",
      value: 80,
       fill: "var(--color-complianceLevel)"
    },
    {
      name: "Current Maturity",
      value: 10,
        fill: "var(--color-currentMaturity)"
    },
  
    
  ];

 preventativeData = [
    {
      name: "Compliance Level",
      value: 62,
       fill: "var(--color-complianceLevel)"
    },
    {
      name: "Current Maturity",
      value: 38,
        fill: "var(--color-currentMaturity)"
    },
  ];
 correctiveData = [
    {
      name: "Compliance Level",
      value: 33,
      fill: "var(--color-complianceLevel)"
    },
    {
      name: "Current Maturity",
      value: 67,
       fill: "var(--color-currentMaturity)"
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      detectiveData: observable,
      preventativeData: observable,
      correctiveData: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(data) {
    const index = this.data.findIndex((u) => u.id === data.id);
    if (index !== -1) {
      this.data.splice(index, 1, data);
      this.data = [...this.data];
    }
}


  *load(query, options) {}
}
