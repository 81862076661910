import { z } from "zod";

const ControlRequirementSchema = z
.object({
  title: z
    .string()
    .min(1, { message: "Title is required." })
    .max(400, { message: "Title must not be longer than 400 characters." }),
  guidance: z.string().min(1, { message: "Guidance is required." }),
  defaultResponsibleAssignee: z.string(),
  frequency: z.string().optional().nullable(),
  dueDate:z.union([z.string().datetime().optional(), z.null()]),
})
  .strict();

export default ControlRequirementSchema;
