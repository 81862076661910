import { z } from "zod";
import { ReportTypes } from "../containers/reports/ReportTypes";

const CreateReportSchema = z
  .object({
    title: z
      .string()
      .min(1, { message: "Title is required." })
      .max(400, { message: "Title must not be longer than 400 characters." }),
    type: z.enum(Object.values(ReportTypes)),
    shortCode: z.string().optional().nullable(),
    description: z.string().min(1, { message: "Description is required." }),
    groupId: z.string().uuid(),
    settings: z
      .object({
        version: z.number().int().min(1, { message: "Version is required" }),
      })
      .passthrough()
      .optional()
      .default({}),
  })
  .strict();

export default CreateReportSchema;
