import { makeObservable, observable, flow, computed } from "mobx";
import { toast } from "sonner";

import { api } from "@/lib/services";

import BrowseTargetRolesSchema from "@/areas/subscriptions/schemas/BrowseTargetRolesSchema";

export default class BrowseTargetRolesStore {
  loading = false;
  roles = [];

  constructor(parentStore, apiPrefix) {
    makeObservable(this, {
      loading: observable,
      roles: observable,
      load: flow,
      filterRoles: computed,
      promotedRoles: computed,
    });

    this.parentStore = parentStore;
    this.apiPrefix = apiPrefix;
  }

  get filterRoles() {
    return this.roles.filter((x) => x.isPromoted);
  }

  get promotedRoles() {
    return this.roles.filter((x) => x.isPromoted);
  }

  *load(query, options) {
    const parseResult = BrowseTargetRolesSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      this.loading = true;
      const response = yield api.get(`${this.apiPrefix}`, {
        params: query,
      });
      this.roles = response.data.items;
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while loading target roles", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
