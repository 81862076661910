import React from "react";

export function InventoryIcon({ className }) {
    return (
        <svg
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="none"
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="currentColor"
                d="M12.9347,2.87782 L20.1164,6.67404 C21.1818,7.23718 21.1818,8.76316 20.1164,9.32629 L18.8416,10.0002 L20.1164,10.674 C21.1818,11.2372 21.1818,12.7632 20.1164,13.3263 L18.8416,14.0002 L20.1164,14.674 C21.1818,15.2372 21.1818,16.7632 20.1164,17.3263 L12.9347,21.1225 C12.35,21.4316 11.6502,21.4316 11.0654,21.1225 L3.88373,17.3263 C2.81837,16.7632 2.81838,15.2372 3.88373,14.674 L5.15856,14.0002 L3.88373,13.3263 C2.81837,12.7632 2.81838,11.2372 3.88373,10.674 L5.15856,10.0002 L3.88373,9.32629 C2.81838,8.76315 2.81838,7.23718 3.88373,6.67404 L11.0654,2.87782 C11.6502,2.56872 12.35,2.56872 12.9347,2.87782 Z M16.7017,15.1313 L12.9347,17.1225 C12.35,17.4316 11.6502,17.4316 11.0654,17.1225 L7.29841,15.1313 L5.65464,16.0002 L12.0001,19.3543 L18.3455,16.0002 L16.7017,15.1313 Z M16.7017,11.1313 L12.9347,13.1225 C12.4031545,13.4035 11.7764851,13.4290455 11.2275693,13.1991364 L11.0654,13.1225 L7.29841,11.1313 L5.65464,12.0002 L12.0001,15.3543 L18.3455,12.0002 L16.7017,11.1313 Z M12.0001,4.646 L5.65464,8.00017 L12.0001,11.3543 L18.3455,8.00017 L12.0001,4.646 Z"
            ></path>
        </svg>
    );
}
