import { makeObservable, observable, action, computed } from "mobx";
import { v4 as uuid } from "uuid";

import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

import { Spinner } from "@/components/elements/Spinner";

export class NotificationStore {
    items = [];

    constructor(rootStore) {
        makeObservable(this, {
            items: observable,
            success: action,
            visible: computed,
            hide: action,
        });

        this.rootStore = rootStore;
    }

    get visible() {
        return this.items.filter((i) => i.visible);
    }

    hide(id) {
        const notification = this.items.find((i) => i.id === id);
        notification.visible = false;
        return notification;
    }

    task(options) {
        return this.add({
            ...options,
            type: "task",
            icon: Spinner,
            color: "text-blue-400",
        });
    }

    error(options) {
        return this.add({
            ...options,
            type: "error",
            icon: ExclamationCircleIcon,
            color: "text-red-400",
        });
    }

    warning(options) {
        return this.add({
            ...options,
            type: "warning",
            icon: ExclamationCircleIcon,
            color: "text-orange-400",
        });
    }

    success(options) {
        return this.add({
            ...options,
            type: "success",
            icon: CheckCircleIcon,
            color: "text-green-400",
        });
    }

    add(options) {
        const item = {
            id: uuid(),
            visible: true,
            ...options,
        };

        this.items = [item, ...this.items];
        return item;
    }
}
