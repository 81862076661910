import { z } from "zod";

const parentSchema = z.object({
  id: z.string().uuid({ message: "Id is required." }),
});

const modelSchema = z.object({
  id: z.string().uuid({ message: "Id is required." }),
});

const CreateEntitySchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    shortCode: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    model: modelSchema.required(),
    parent: parentSchema.optional().nullable(),
  })
  .strict();

export default CreateEntitySchema;
