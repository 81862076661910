import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ControlComplianceReportStore {
  loading = false;
  pagination = {};
  config = {};
  query = { search: "", page: 1 };
  data = [
    {
      id: 1,
      groupName: "Governance",
      children: [
        {
          id: 1.1,
          name: "Inventory and Control of Hardware Assets",
        },
        {
          id: 1.2,
          name: "Inventory and Control of Software Assets",
        },
        {
          id: 1.3,
          name: "Secure Configuration for Hardware and Software",
        },
        {
          id: 1.4,
          name: "Email and Web Browser Protections",
        },
      ],
    },
    {
      id: 2,
      groupName: "Vulnerability and Threat Protection",
      children: [
        {
          id: 2.1,
          name: "Information classification",
        },
        {
          id: 2.2,
          name: "Media handling",
        },
        {
          id: 2.3,
          name: "Business requirements of access control",
        },
        {
          id: 2.4,
          name: "User access management",
        },
      ],
    },
    {
      id: 3,
      groupName: "Identity",
      children: [
        {
          id: 3.1,
          name: "System and application access control",
        },
        {
          id: 3.2,
          name: "Maintenance, Monitoring and Analysis of Audit Logs",
        },
        {
          id: 3.3,
          name: "Information systems audit considerations",
        },
        {
          id: 3.4,
          name: "Incident Response and Management",
        },
        {
          id: 3.5,
          name: "Security in development and support processes",
        },
      ],
    },
    {
      groupName: "Operation",
      children: [
        {
          name: "Compliance with legal and contractual requirements",
        },
        {
          name: "Information security reviews",
        },
        {
          name: "Management of information security incidents & improvements",
        },
      ],
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      data: observable,
      config: observable,
      actions: computed,
      setSearchText: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];
    return list;
  }

  // TODO: work on set search text with zubair ahmed
  setSearchText(search) {
    this.query.search = search;
  }

  *load(query, options) {}
}
