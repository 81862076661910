import { Suspense, lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";

const OrgAppLayout = lazy(() => import("@/components/layout/org/OrgAppLayout"));
const SessionExpiredPage = lazy(() => import("@/areas/auth/containers/pages/SessionExpiredPage"));
const AccessDeniedPage = lazy(() => import("@/areas/auth/containers/pages/AccessDeniedPage"));
const SignedOutPage = lazy(() => import("@/areas/auth/containers/pages/SignedOutPage"));

import { NotificationContext } from "@/areas/notifications/stores/NotificationContext";
import { AuthenticationProvider } from "@/areas/auth/components/auth/AuthenticationProvider";

import { StoreContext } from "@/stores/StoreContext";
import { Store } from "@/stores/Store";
import globalRouter from "@/globalRouter";

const rootStore = new Store();

function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <StoreContext.Provider value={rootStore}>
      <NotificationContext.Provider value={rootStore.notificationStore}>
        <Suspense fallback="">
          <Routes>
            <Route path="/signed-out" element={<SignedOutPage />} />
            <Route path="/access-denied" element={<AccessDeniedPage />} />
            <Route path="/session-expired" element={<SessionExpiredPage />} />
            <Route
              path="*"
              element={
                <AuthenticationProvider>
                  <OrgAppLayout />
                </AuthenticationProvider>
              }
            />
          </Routes>
        </Suspense>
        <Toaster /* richColors */ />
      </NotificationContext.Provider>
    </StoreContext.Provider>
  );
}

export default App;
