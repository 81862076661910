import { makeObservable, observable, flow, action } from "mobx";

export class TenantRedeemStore {
    data = {
        id: null,
        name: null,
        description: null,
        membershipPolicy: "organisation",
    };
    options = {};
    saving = false;
    error = null;

    constructor(tenantStore) {
        makeObservable(this, {
            data: observable,
            options: observable,
            error: observable,
            saving: observable,
            merge: action,
            reset: action,
            save: flow,
        });

        this.tenantStore = tenantStore;
        this.tenantService = tenantStore.tenantService;
        this.notificationStore = tenantStore.rootStore.notificationStore;
    }

    merge(options) {
        this.data = { ...this.data, ...options };
    }

    reset() {
        this.data = {
            id: null,
            name: null,
            description: null,
            membershipPolicy: "organisation",
        };
        this.options = {};
        this.saving = false;
        this.error = null;
    }

    *save() {
        let notification = null;
        try {
            this.saving = true;

            notification = this.notificationStore.task({
                title: "Organisation provisioning started",
                description: `Organisation ${this.data.name} is being provisioned you should soon be redirected to your very own instance.`,
            });

            let tenant = yield this.tenantService.redeemInvitation(this.data);
            do {
                yield new Promise((resolve) => setTimeout(resolve, 1000));
                tenant = yield this.tenantService.getTenant({ id: tenant.id });
            } while (tenant.status !== "provisioned");

            this.reset();
            window.location.href = `https://${tenant.domains[0]}`;
            return invitation;
        } catch (e) {
            this.error = e;
            this.notificationStore.error({
                title: e.title,
                description: e.errors ? Object.values(e.errors).join("\r\n") : null,
            });
            throw e;
        } finally {
            if (notification) {
                this.notificationStore.hide(notification.id);
            }
            this.saving = false;
        }
    }
}
