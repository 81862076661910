import { z } from "zod";

const registrySchema = z.object({
  id: z.string().uuid(),
});

const parentSchema = z.object({
  id: z.string().uuid(),
});

const CreateDomainSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    shortCode: z.string().optional().nullable(),
    description: z.string().optional(),
    registry: registrySchema.optional(),
    parent: parentSchema.optional().nullable(),
  })
  .strict();

export default CreateDomainSchema;
