import { ServiceBase } from "@/lib/services";
import { stringify } from "query-string";

export class TenantService extends ServiceBase {
    baseUrl = "http://localhost:49160/api";

    getInvitation(options) {
        return this.get(`${this.baseUrl}/v1/invitations/${options.id}`);
    }

    sendInvitation(options) {
        return this.post(`${this.baseUrl}/v1/invitations`, options);
    }

    redeemInvitation(options) {
        return this.post(`${this.baseUrl}/v1/invitations/${options.id}/redeem`, options);
    }

    getTenant(options) {
        return this.get(`${this.baseUrl}/v1/tenants/${options.id}`);
    }

    getTenants(options) {
        return this.get(`${this.baseUrl}/v1/tenants?${stringify(options)}`);
    }

    getMyTenants(options) {
        return this.get(`${this.baseUrl}/v1/tenants/my?${stringify(options)}`);
    }
}
