import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const httpBackendOptions = {
  /*
   path where resources get loaded from, or a function returning a path:
   function(lngs, namespaces) { return customPath; }
   the returned path will interpolate lng, ns if provided like giving a static path
   the function might return a promise returning falsy will abort the download
   If allowMultiLoading is false, lngs and namespaces will have only one element each,
   If allowMultiLoading is true, lngs and namespaces can have multiple elements
   loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}'
  */

  loadPath: "/locales/{{lng}}/{{ns}}.json",

  /*
  path to post missing resources, or a function
  function(lng, namespace) { return customPath; }
  the returned path will interpolate lng, ns if provided like giving a static path
  */

  addPath: "/locales/add/{{lng}}/{{ns}}",

  /*
  your backend server supports multi loading, /locales/resources.json?lng=de+en&ns=ns1+ns2
   Adapter is needed to enable MultiLoading, use 18next-multiload-backend-adapter plugin
   Returned JSON structure in this case is
   {
    lang : {
    namespaceA: {},
    namespaceB: {},
    ...etc
    }
   }
  set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
  */
  allowMultiLoading: false,

  // parse data after it has been fetched
  parse: function (data) {
    return data;
  },

  //parse data before it has been sent by addPath
  parsePayload: function (namespace, key, fallbackValue) {
    return { key };
  },

  // allow cross domain requests
  crossDomain: false, // can we make it true? as we have multiple domains

  // allow credentials on cross domain requests
  withCredentials: false,

  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: false,

  /* custom request headers sets request.setRequestHeader(key, value)
  Send additional information, such as authentication tokens or other custom headers, along with the HTTP requests.*/
  customHeaders: {
    authorization: "foo",
    // ...
  },
};

const detectionOptions = {
  order: ["localStorage"],
  lookupLocalStorage: "lang",
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectionOptions,
    options: httpBackendOptions,
    debug: !!import.meta.env.DEV,
    fallbackLng: "en-GB",
    namespace: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: ["en-GB", "en-US"],
    defaultNS: "common",
    ns: ["common", "controls"],
    load: "currentOnly",
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
      transWrapTextNodes: "",
    },
  });

export default i18n;
