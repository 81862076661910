import BrowseAuditsStore from "@/areas/audits/stores/BrowseAuditsStore";
import CreateAuditStore from "./CreateAuditStore";

export default class AuditRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseAuditsStore = new BrowseAuditsStore(this);
    this.createAuditStore = new CreateAuditStore(this);
  }
}
