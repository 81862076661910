import { makeObservable, observable, flow, action } from "mobx";
// import { api } from "@/lib/services";
// import { ApiRoutes } from "@/lib/routes";

// import ControlRequirementSchema from "@/areas/controls/schemas/ControlRequirementSchema";

export default class ControlHistoryViewerStore {
  visible = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    console.log("first");
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    //     const parseResult = ControlRequirementSchema.safeParse(data);
    //     if (!parseResult.success) {
    //       return parseResult;
    //     }

    const loadData = async (data) => {
      const getResponse2sLater = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: 201,
              data: data,
            });
          }, 2000);
        });
      };

      const response = await getResponse2sLater();
      if (response.status == 201) {
        console.log("Success");
        this.visible = false;
        return { success: true, data: response.data };
      }
    };
    try {
      const result = loadData(data);
      return result;
    } catch (e) {
      return { success: false, error: e };
    }
  }
}
