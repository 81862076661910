import { makeObservable, observable, flow, action } from "mobx";

export class TenantInvitationStore {
    data = {
        email: null,
        name: null,
        domain: null,
        sku: "standard",
    };
    options = {};
    saving = false;
    error = null;

    constructor(tenantStore) {
        makeObservable(this, {
            data: observable,
            options: observable,
            saving: observable,
            error: observable,
            merge: action,
            reset: action,
            save: flow,
        });

        this.tenantStore = tenantStore;
        this.tenantService = tenantStore.tenantService;
        this.notificationStore = tenantStore.rootStore.notificationStore;
    }

    merge(options) {
        this.data = {
            ...this.data,
            ...options,
        };
    }

    reset() {
        this.data = {
            email: null,
            name: null,
            domain: null,
            sku: "standard",
        };
        this.options = {};
        this.saving = false;
        this.error = null;
    }

    *save() {
        try {
            this.saving = true;
            const invitation = yield this.tenantService.sendInvitation(this.data);
            this.reset();

            this.notificationStore.success({
                title: "Invitation sent successfully",
                description: `${invitation.name} should receive an email shortly with a link which can be used to create a new organisation.`,
            });

            return invitation;
        } catch (e) {
            this.error = e;
            this.notificationStore.error({
                title: e.title,
                description: e.errors ? Object.values(e.errors).join("\r\n") : null,
            });
            throw e;
        } finally {
            this.saving = false;
        }
    }
}
