import { makeObservable, observable, flow, action } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { toast } from "sonner";

import CreateRoleSchema from "@/areas/settings/schemas/CreateRoleSchema";

export default class CreateRoleStore {
  visible = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    console.log(data);
    const parseResult = CreateRoleSchema.safeParse(data);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      console.log(data);
      const response = yield api.post(ApiRoutes.forRoles(), data);
      console.log(response);
      this.visible = false;
      toast.success("Role created successfully");
      return { success: true, data: response.data };
    } catch (e) {
      console.log(e);
      toast.error("Error while creating role", {
        description: e.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    }
  }
}
