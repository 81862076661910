import { makeObservable, observable, flow, action } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import UpdateControlRequirementSchema from "@/areas/controls/schemas/UpdateControlRequirementSchema";

export default class UpdateControlRequirementStore {
  visible = false;
  processing = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      processing: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    try {
      this.processing = true;

      const parseResult = UpdateControlRequirementSchema.safeParse(data);
      if (!parseResult.success) {
        return parseResult;
      }

      const response = yield api.put(ApiRoutes.forControl(data.id), data);
      if (response.status == 202) {
        this.visible = false;
        toast.success("Control updated successfully");
        this.parentStore.load({ id: data.id });

        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected error code");
      }
    } catch (e) {
      toast.error("Error while updating control", {
        description: "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.processing = false;
    }
  }
}
