import React from "react";
import { cn } from "@/lib/utils";
import { LoaderIcon } from "@/components/icons/LoaderIcon";

const LoadingIndicator = ({ className, message, center, size, inline }) => {
  return (
    <div
      className={cn(
        center && "m-auto flex h-full max-w-7xl items-center justify-center px-4 sm:px-6 lg:px-8",
        className
      )}
    >
      <div className={cn(center && "flex items-center justify-center")}>
        <button
          type="button"
          className={cn(
            "cursor-none items-center py-4 font-semibold leading-6 text-primary transition duration-150 ease-in-out",
            (!size || size === "lg") && "text-lg",
            size === "sm" && "text-sm",
            inline === false ? "flex" : "inline-flex",
            inline === false && center && "mx-auto"
          )}
          disabled
        >
          <LoaderIcon variant="primary" className="-ml-1 mr-3" />
          {message}
        </button>
      </div>
    </div>
  );
};

export default LoadingIndicator;
