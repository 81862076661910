import { z } from "zod";

// const registrySchema = z.object({
//   id: z.string().uuid(),
// });

const domainSchema = z.object({
  id: z.string().uuid(),
});

const CreateControlSchema = z
  .object({
    title: z
      .string()
      .min(1, { message: "Title is required." })
      .max(400, { message: "Title must not be longer than 400 characters." }),
    shortCode: z.string().optional().nullable(),
    description: z.string().min(1, { message: "Description is required." }),
    //    registry: registrySchema,
    domain: domainSchema,
  })
  .strict();

export default CreateControlSchema;
