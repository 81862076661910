import BrowseControlsStore from "@/areas/controls/stores/BrowseControlsStore";
import BrowseDomainStore from "@/areas/controls/stores/BrowseDomainStore";
import CreateControlStore from "@/areas/controls/stores/CreateControlStore";
import CreateControlRequirementStore from "@/areas/controls/stores/CreateControlRequirement";
import ControlHistoryViewerStore from "@/areas/controls/stores/ControlHistoryViewerPage";
import CreateDomainStore from "@/areas/controls/stores/CreateDomainStore";
import UpdateCurrentActivityStore from "@/areas/controls/stores/UpdateCurrentActivityStore";
import UpdateControlRequirementStore from "@/areas/controls/stores/UpdateControlRequirementStore";
import CreateControlSubdomainStore from "@/areas/controls/stores/CreateControlSubdomainStore";
import CreateSubdomainStore from "@/areas/controls/stores/CreateSubdomainStore";
import BrowseSubdomainStore from "@/areas/controls/stores/BrowseSubdomainStore";
import BrowseDomainControlsStore from "@/areas/controls/stores/BrowseDomainControlsStore";

export default class ControlRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseControlsStore = new BrowseControlsStore(this);
    this.browseDomainStore = new BrowseDomainStore(this);
    this.createDomainStore = new CreateDomainStore(this);
    this.createControlStore = new CreateControlStore(this);
    this.createControlRequirementStore = new CreateControlRequirementStore(this);
    this.controlHistoryViewerPage = new ControlHistoryViewerStore(this);
    this.updateCurrentActivityStore = new UpdateCurrentActivityStore(this);
    this.createSubdomainStore = new CreateSubdomainStore(this);
    this.updateControlRequirementStore = new UpdateControlRequirementStore(this);
    this.createControlSubdomainStore = new CreateControlSubdomainStore(this);
    this.browseSubdomainStore = new BrowseSubdomainStore(this);
    this.browseDomainControlsStore = new BrowseDomainControlsStore(this);
  }
}
