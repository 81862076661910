import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ControlImpactReportStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  config = {};
  confidentialityData = [
    {
      name: "Compliant",
      value: 72,
      fill: "var(--color-complianceLevel)",
    },

    {
      name: "Maturity Target",
      value: 80,
      fill: "var(--color-currentMaturity)",
    },
  ];
  integrityData = [
    {
      name: "Compliant",
      value: 34,
      fill: "var(--color-complianceLevel)",
    },
    {
      name: "Maturity Target",
      value: 66,
      fill: "var(--color-currentMaturity)",
    },
  ];
  availabilityData = [
    {
      name: "Compliant",
      value: 100,
      fill: "var(--color-complianceLevel)",
    },
    {
      name: "Maturity Target",
      value: 100,
      fill: "var(--color-currentMaturity)",
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      confidentialityData: observable,
      integrityData: observable,
      availabilityData: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(data) {
    const index = this.data.findIndex((u) => u.id === data.id);
    if (index !== -1) {
      this.data.splice(index, 1, data);
      this.data = [...this.data];
    }
  }

  *load(query, options) {}
}
