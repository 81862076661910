import { makeObservable, observable, flow, action } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import CreateAuditSchema from "@/areas/audits/schemas/CreateAuditSchema";

export default class CreateAuditStore {
  visible = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    const parseResult = CreateAuditSchema.safeParse(data);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      // const response = yield api.post(ApiRoutes.forRisks(), data);
      // if (response.status == 201) {
      //   this.visible = false;
      //   return { success: true, data: response.data };
      // }
    } catch (e) {
      return { success: false, error: e };
    }
  }
}
