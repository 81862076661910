import { z } from "zod";
import UserRefByIdSchema from "@/areas/directory/schemas/UserRefByIdSchema";

const UpdateControlRequirementSchema = z
  .object({
    id: z.string().uuid({ message: "Id is required." }),
    title: z
      .string()
      .min(1, { message: "Title is required." })
      .max(400, { message: "Title must not be longer than 400 characters." }),
    guidance: z.string().min(1, { message: "Description is required." }),
    defaultResponsibleOwner: UserRefByIdSchema,
   
  })
  .strict();

export default UpdateControlRequirementSchema;
