import BrowseModelsStore from "@/areas/inventory/stores/BrowseModelsStore";
import CreateModelStore from "@/areas/inventory/stores/CreateModelStore";
import CreateEntityStore from "@/areas/inventory/stores/CreateEntityStore";

export default class InventoryRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.browseModelsStore = new BrowseModelsStore(this);
    this.createModelStore = new CreateModelStore(this);
    this.createEntityStore = new CreateEntityStore(this);
  }
}
