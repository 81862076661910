import { makeObservable, observable, flow, computed, action } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { UserPlus2 } from "lucide-react";

import BrowseUsersSchema from "@/areas/settings/schemas/BrowseUsersSchema";

import InviteUsersStore from "@/areas/settings/stores/InviteUsersStore";

export default class BrowseUsersStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  users = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      users: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
      processing: computed,
    });

    this.parentStore = parentStore;

    this.inviteUsersStore = new InviteUsersStore(this);
  }

  get actions() {
    const list = [];

    list.push({
      id: "settings:users:invite",
      label: "Invite Users",
      onClick: () => this.inviteUsersStore.show(),
      icon: UserPlus2,
      location: "primary",
      processing: this.inviteUsersStore.processing,
      disabled: this.processing,
    });

    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  get processing() {
    return this.loading || this.inviteUsersStore.processing;
  }

  consume(user) {
    const index = this.users.findIndex((u) => u.id === user.id);
    if (index !== -1) {
      this.users.splice(index, 1, user);
      this.users = [...this.users];
    }
  }

  *load(query, options) {
    const parseResult = BrowseUsersSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;
      this.users = [];
      this.query = parseResult.data;

      const response = yield api.get(ApiRoutes.forUsers(), {
        params: query,
      });

      if (response.status == 200) {
        this.users = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      } else {
        throw new Error("Unexpected status code");
      }

    } catch (e) {
      toast.error("Error loading users", {
        description: e?.response?.data?.message || "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
