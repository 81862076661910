import { z } from "zod";

const UserRefByIdSchema = z
  .object(
    {
      id: z.string().uuid({ message: "User identifier is required" }),
      name: z.string().min(1, { message: "User name is required" }),
      jobTitle: z.string().optional().nullable(),
      email: z.string().email().optional(),
      role: z.string().optional(),
    },
    { invalid_type_error: "Expected user" }
  )
  .strict();

export default UserRefByIdSchema;
