import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ExecutiveRiskReportStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  config = {};
  totalRisks = 20;
  criticalRisks = 15;
  risksByImpact = [{ critical: 8, major: 5, moderate: 9, minor: 3 }];
  risksByDepartment = [
    { department: "Security", critical: 2, major: 2, moderate: 1, minor: 2 },
    { department: "Finance", critical: 1, major: 5, moderate: 3, minor: 2 },
    { department: "Cyber", critical: 1, major: 2, moderate: 1, minor: 1 },
    { department: "Infosec", critical: 3, major: 1, moderate: 2, minor: 2 },
  ];
  risksByAccountable = [
    { name: "Sam Curren", critical: 4, major: 2, moderate: 1, minor: 2 },
    { name: "John Gibbs", critical: 2, major: 5, moderate: 3, minor: 2 },
    { name: "Ben Harry", critical: 1, major: 1, moderate: 1, minor: 1 },
    { name: "Robert Hogg", critical: 3, major: 1, moderate: 2, minor: 1 },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      config: observable,
      query: observable,
      risksByImpact: observable,
      risksByDepartment: observable,
      risksByAccountable: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }

  get actions() {
    const list = [];
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(riskType) {
    const index = this.report.findIndex((u) => u.id === riskType.id);
    if (index !== -1) {
      this.report.splice(index, 1, riskType);
      this.report = [...this.report];
    }
  }

  consume(riskType) {
    const index = this.fields.findIndex((u) => u.id === riskType.id);
    if (index !== -1) {
      this.fields.splice(index, 1, riskType);
      this.fields = [...this.fields];
    }
  }

  *load(query, options) {}
}
