import { NotificationStore } from "@/areas/notifications/stores/NotificationStore";
import AppNavigationStore from "@/areas/apps/stores/AppNavigationStore";
import { TenantStore } from "@/areas/tenants/stores/TenantStore";
import { AuthenticationStore } from "@/areas/auth/stores/AuthenticationStore";
import SettingsRootStore from "@/areas/settings/stores/SettingsRootStore";
import RiskRootStore from "@/areas/risks/stores/RiskRootStore";
import InventoryRootStore from "@/areas/inventory/stores/InventoryRootStore";
import ComplianceRootStore from "@/areas/compliance/stores/ComplianceRootStore";
import ControlRootStore from "@/areas/controls/stores/ControlRootStore";
import AuditRootStore from "@/areas/audits/stores/AuditRootStore";
import ActivityRootStore from "@/areas/activities/stores/ActivityRootStore";
import ReportRootStore from "@/areas/reports/stores/ReportRootStore";
import AnalyticsRootStore from "@/areas/analytics/stores/AnalyticsRootStore";

export class Store {
  constructor() {
    this.notificationStore = new NotificationStore(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.appNavigationStore = new AppNavigationStore(this);

    this.tenantStore = new TenantStore(this);
    this.settingsStore = new SettingsRootStore(this);
    this.riskStore = new RiskRootStore(this);
    this.inventoryStore = new InventoryRootStore(this);
    this.complianceStore = new ComplianceRootStore(this);
    this.controlStore = new ControlRootStore(this);
    this.activityStore = new ActivityRootStore(this);
    this.auditStore = new AuditRootStore(this);
    this.reportStore = new ReportRootStore(this);

    this.analyticsStore = new AnalyticsRootStore(this);
  }
}
