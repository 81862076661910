import { z } from "zod";

const BrowseUsersSchema = z
  .object({
    search: z.string(),
    page: z.number().min(1, { message: "Page is out of bounds" }),
  })
  .strict();

export default BrowseUsersSchema;
