import { makeObservable, observable, flow, computed, action } from "mobx";

export default class ComplianceMaturityTrendStore {
  loading = false;
  pagination = {};
  query = { search: "", page: 1 };
  data = [];
  options = [
    { value: "monthly", name: "Monthly" },
    { value: "yearly", name: "Yearly" },
  ];
  config = {};

  complianceMaturityScores = [
    { name: "Sep", complianceLevel: 45, maturityLevel: 30 },
    { name: "Oct", complianceLevel: 55, maturityLevel: 45 },
    { name: "Nov", complianceLevel: 60, maturityLevel: 50 },
    { name: "Dec", complianceLevel: 62, maturityLevel: 52 },
    { name: "Jan", complianceLevel: 63, maturityLevel: 55 },
    { name: "Feb", complianceLevel: 64, maturityLevel: 58 },
    { name: "Mar", complianceLevel: 65, maturityLevel: 60 },
    { name: "Apr", complianceLevel: 68, maturityLevel: 62 },
    { name: "May", complianceLevel: 70, maturityLevel: 53 },
    { name: "Jun", complianceLevel: 73, maturityLevel: 65 },
    { name: "Jul", complianceLevel: 75, maturityLevel: 68 },
    { name: "Aug", complianceLevel: 78, maturityLevel: 70 },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      data: observable,
      config: observable,
      options: observable,
      complianceMaturityScores: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }
  get actions() {
    const list = [];

    list.push({});
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(data) {
    const index = this.data.findIndex((u) => u.id === data.id);
    if (index !== -1) {
      this.data.splice(index, 1, data);
      this.data = [...this.data];
    }
  }

  *load(query, options) {}
}
