import { z } from "zod";

const BrowseControlsQueryDomainSchema = z.object({
  id: z.string().array().nullable(),
});

const BrowseControlsQuerySchema = z.object({
  domain: BrowseControlsQueryDomainSchema,
});

const BrowseControlsSchema = z.object({
  search: z.string(),
  page: z.number().min(1, { message: "Page is out of bounds" }),
  query: z.any(),
  sort: z.array(z.object({ property: z.string(), asc: z.boolean() })),
});

export default BrowseControlsSchema;
