import { makeObservable, observable, flow, computed } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import { Add } from "@/components/icons/IconMap";

import BrowseTreatmentsSchema from "@/areas/risks/schemas/BrowseTreatmentsSchema";

export default class BrowseTreatmentsStore {
  loading = false;
  pagination = null;
  treatments = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      treatments: observable,
      actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "treatments:report",
      label: "Report a Risk",
      onClick: () => this.parentStore.createRiskStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createRiskStore.processing,
      disabled: this.parentStore.createRiskStore.processing,
    });

    return list;
  }

  *load(query, options) {
    const parseResult = BrowseTreatmentsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }

    try {
      this.loading = true;

      const response = yield api.post(ApiRoutes.forTreatmentSearch(), query);

      this.treatments = response.data.items;
      this.pagination = {
        page: response.data.page,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
        pageSize: response.data.pageSize,
      };
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
