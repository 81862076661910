import { z } from "zod";

const CreateTargetSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    shortCode: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    parentId: z.string().uuid().optional().nullable(),
  })
  .strict();

export default CreateTargetSchema;
