import { z } from "zod";

const RiskHeatmapSchema = z.object({
  search: z.string(),
  page: z.number().min(1, { message: "Page is out of bounds" }),
  query: z.any(),
  sort: z.array(z.object({ property: z.string(), asc: z.boolean() })),
  view: z.enum(["inherent", "residual", "appetite"]).optional().nullable(),
  selection: z
    .object({
      likelihood: z.number().int().nullish(),
      impact: z.number().int().nullish(),
    })
    .nullable(),
});

export default RiskHeatmapSchema;
