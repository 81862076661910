import { z } from "zod";

const CreateRoleSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    type: z.string().min(1, { message: "Permission level is required." }),
    isPromoted: z.boolean(),
    isUnique: z.boolean(),
    order: z.coerce.string().min(0, { message: "Order must be more than 0" }),
    appliesTo: z.array(z.string()).min(1, { message: "Applies to is required" }),
  })
  .strict();

export default CreateRoleSchema;
