import {
  PlayIcon,
  ActivitySquareIcon,
  SaveIcon,
  Trash2Icon,
  XSquareIcon,
  UserPlusIcon,
  EditIcon,
  BookOpenCheckIcon,
  PlusIcon,
  MailIcon,
  ArrowDownSquareIcon,
  CheckSquare2Icon,
  LockIcon,
  WorkflowIcon,
  Wand2Icon,
  DownloadIcon,
  RocketIcon,
  MinusCircleIcon,
} from "lucide-react";
import { LoaderIcon } from "./LoaderIcon";

export const Loader = LoaderIcon;
export const Notification = MailIcon;
export const Workflow = WorkflowIcon;
export const Magic = Wand2Icon;
export const Save = SaveIcon;
export const Edit = EditIcon;
export const Add = PlusIcon;
export const Lock = LockIcon;
export const Assign = UserPlusIcon;
export const Start = PlayIcon;
export const Review = BookOpenCheckIcon;
export const Submit = ArrowDownSquareIcon;
export const Monitor = ActivitySquareIcon;
export const Close = XSquareIcon;
export const Complete = CheckSquare2Icon;
export const Delete = Trash2Icon;
export const Download = DownloadIcon;
export const Deploy = RocketIcon;
export const Publish = RocketIcon;
export const Activate = PlayIcon;
export const Decommission = MinusCircleIcon;
