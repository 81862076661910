import { z } from "zod";

const OrgUserRefByIdSchema = z
  .object(
    {
      id: z.string().uuid({ message: "User identifier is required" }),
      name: z.string().min(1, { message: "User name is required" }),
      email: z.string().email().optional(),
      domainId: z.string().optional(),
    },
    { invalid_type_error: "Expected user" }
  )
  .strict();

export default OrgUserRefByIdSchema;
