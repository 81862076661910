import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "@/stores/useStores";
import LoadingIndicator from "@/components/layout/org/LoadingIndicator";
import SessionExpiredDialog from "../sessions/SessionExpiredDialog";

export const AuthenticationProvider = observer(({ children }) => {
  const { authenticationStore } = useStores();

  useEffect(() => {
    authenticationStore.init();
  }, [authenticationStore]);

  if (authenticationStore.isAuthenticated) {
    return (
      <>
        {children}
        <SessionExpiredDialog
          visible={authenticationStore.showSessionExpired}
          processing={authenticationStore.processing}
          onCancel={() => authenticationStore.setShowSessionExpired(false)}
          onLogin={() => authenticationStore.loginRedirect()}
        />

        <iframe id={authenticationStore.iframeId} style={{ visibility: "hidden", height: "0px" }} />
      </>
    );
  }
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <LoadingIndicator center message="logging in..." />
      <iframe id={authenticationStore.iframeId} style={{ visibility: "hidden", height: "0px" }} />
    </div>
  );
});
