import { makeObservable, observable, flow, computed, action } from "mobx";

export default class BrowseNotificationStore {
  loading = false;
  query = { search: "", page: 1 };
  pagination = {
    page: 1,
    totalItems: 10,
    totalPages: 2,
    pageSize: 5,
  };
  notifications = [
    {
      id: 2,
      app: "Risk",
      title: "[Email] When a Risk is Reported, send a notification to the Risk Team",
      recipients: ["Team"],
      appInNotification: true,
    },
    {
      id: 3,
      app: "Risk",
      title: "[Email] When a Risk is Assigned to a co-ordinator, send a notification to the Risk Co-ordinator",
      recipients: ["Risk Co-ordinator"],
      appInNotification: true,
    },
    {
      id: 4,
      app: "Risk",
      title:
        "[Email] When a Risk is moved to Evaluation, send a notification to the Accountable Owner and Responsible Owner",
      recipients: ["Accountable Owner", "Responsible Owner"],
      appInNotification: true,
    },
    {
      id: 5,
      app: "Risk",
      title:
        "[Email] When a Risk is Accepted, send a notification to the Accountable Owner, Responsible Owner, Risk Coordinator",
      recipients: ["Accountable Owner", "Responsible Owner", "Risk Coordinator"],
      appInNotification: true,
    },
    {
      id: 6,
      app: "Risk",
      title:
        "[Email] When a Risk is Closed, send a notification to the Accountable Owner, Responsible Owner, Risk Coordinator",
      recipients: ["Accountable Owner", "Responsible Owner", "Risk Coordinator"],
      appInNotification: true,
    },
    {
      id: 7,
      app: "Risk",
      title: "[Email] When a Treatment is Created or Assigned, send a notification to the Treatment Owner",
      recipients: ["Treatment Owner"],
      appInNotification: true,
    },
    {
      id: 8,
      app: "Risk",
      title: "[Email] When a Treatment is Completed, Email the Risk Co-ordinator",
      recipients: ["Risk Co-ordinator"],
      appInNotification: true,
    },
    {
      id: 9,
      app: "Risk",
      title: "[Email] When a Treatment is overdue, Email the Treatment Owner and Risk Co-ordinator",
      recipients: ["Treatment Owner", "Risk Co-ordinator"],
      appInNotification: true,
    },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      notifications: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }
  get actions() {
    const list = [];

    list.push({});
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(notification) {
    const index = this.notifications.findIndex((u) => u.id === notification.id);
    if (index !== -1) {
      this.notifications.splice(index, 1, notification);
      this.notifications = [...this.notifications];
    }
  }

  *load(query, options) {}
}
