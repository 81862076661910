import { makeObservable, observable, flow, computed, action } from "mobx";

export default class RiskTreatmentReportStore {
  loading = false;
  pagination = { page: 1, totalItems: 5, totalPages: 1, pageSize: 8 };
  query = { search: "", page: 1 };
  gridViewData = [
    {
      id: "1",
      summary: "Treatment 1",
      description: "Supply chain",
      status: "Overdue - due 2 weeks ago",
      owner: {
        name: "Sarah Harper",
        avatarUrl:
          "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
      },
      risk: {
        code: "R23",
        description: "Inadequate Access Control",
        level: "Critical",
        levelColor: "bg-red-400/90",
      },
      riskOwner: {
        name: "Mark Williams",
        avatarUrl:
          "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
      },
    },
    {
      id: "2",
      summary: "Treatment 2",
      description: "HR",
      status: "Pending - due in 1 week",
      owner: {
        name: "Sam Barsh",
        avatarUrl:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
      risk: {
        code: "R36",
        description: "Weak encryption",
        level: "Critical",
        levelColor: "bg-red-400/90",
      },
      riskOwner: {
        name: "Suliun Keliym",
        avatarUrl:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
    },
    {
      id: "3",
      summary: "Treatment 3",
      description: "Finance",
      status: "Pending - due in 2 months",
      owner: {
        name: "Unassigned",
        avatarUrl: "https://via.placeholder.com/256",
      },
      risk: {
        code: "R37",
        description: "Lack of Monitoring",
        level: "Medium",
        levelColor: "bg-orange-400/90",
      },
      riskOwner: {
        name: "Alex Semuyel",
        avatarUrl:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
    },
    {
      id: "4",
      summary: "Treatment 4",
      description: "Marketing",
      status: "Complete",
      owner: {
        name: "May Harris",
        avatarUrl:
          "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      risk: {
        code: "R44",
        description: "CRM Vulnerabilities",
        level: "Medium",
        levelColor: "bg-orange-400/90",
      },
      riskOwner: {
        name: "Humil Limition",
        avatarUrl:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
    },
    {
      id: "5",
      summary: "Treatment 5",
      description: "Marketing",
      status: "Complete",
      owner: {
        name: "May Harris",
        avatarUrl:
          "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
      risk: {
        code: "R44",
        description: "CRM Vulnerabilities",
        level: "Medium",
        levelColor: "bg-orange-400/90",
      },
      riskOwner: {
        name: "Humil Limition",
        avatarUrl:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
      },
    },
  ];
  treatmentOwners = [
    {
      name: "Sarah Harper",
      value: 2,
      fill: "hsl(var(--secondary))",
      avatar:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
    },
    {
      name: "May Harris",
      value: 2,
      fill: "hsl(var(--secondary))",
      avatar:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Sam Barash",
      value: 1,
      fill: "hsl(var(--secondary))",
      avatar:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
    },
  ];
  treatmentSummary = [
    { name: "All Treatment", value: 7, fill: "hsl(var(--secondary))" },
    { name: "Overdue", value: 1, fill: "hsl(var(--critical))" },
    { name: "Unassigned", value: 1, fill: "hsl(var(--critical))" },
  ];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      gridViewData: observable,
      treatmentOwners: observable,
      treatmentSummary: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }
  get actions() {
    const list = [];

    list.push({});
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(data) {
    const index = this.data.findIndex((u) => u.id === data.id);
    if (index !== -1) {
      this.data.splice(index, 1, data);
      this.data = [...this.data];
    }
  }

  *load(query, options) {}
}
