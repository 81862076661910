import { makeObservable, observable, flow, computed, action } from "mobx";

export default class TreatmentReportStore {
  loading = false;
  query = {};
  pagination = { page: 1, totalItems: 5, totalPages: 1, pageSize: 8 };
  currentRiskScore = 82;
  periodOptions = [
    { value: "monthly", name: "Monthly" },
    { value: "quarterly", name: "Quarterly" },
  ];
  config = {};
  data = {
    chartData: {
      items: [
        { id: 1, date: "2024-01-01T00:00:00+00:00", residualRiskScore: 220, riskAppetiteScore: 450 },
        { id: 2, date: "2024-02-01T00:00:00+00:00", residualRiskScore: 240, riskAppetiteScore: 455 },
        { id: 3, date: "2024-03-01T00:00:00+00:00", residualRiskScore: 290, riskAppetiteScore: 490 },
        { id: 4, date: "2024-04-01T00:00:00+00:00", residualRiskScore: 340, riskAppetiteScore: 460 },
        { id: 5, date: "2024-05-01T00:00:00+00:00", residualRiskScore: 370, riskAppetiteScore: 465 },
        { id: 6, date: "2024-06-01T00:00:00+00:00", residualRiskScore: 330, riskAppetiteScore: 455 },
        { id: 7, date: "2024-07-01T00:00:00+00:00", residualRiskScore: 360, riskAppetiteScore: 470 },
        { id: 8, date: "2024-08-01T00:00:00+00:00", residualRiskScore: 350, riskAppetiteScore: 465 },
        { id: 9, date: "2024-09-01T00:00:00+00:00", residualRiskScore: 390, riskAppetiteScore: 475 },
        { id: 10, date: "2024-10-01T00:00:00+00:00", residualRiskScore: 370, riskAppetiteScore: 480 },
        { id: 11, date: "2024-11-01T00:00:00+00:00", residualRiskScore: 410, riskAppetiteScore: 485 },
        { id: 12, date: "2024-12-01T00:00:00+00:00", residualRiskScore: 430, riskAppetiteScore: 490 },
      ],
    },
    gridData: {
      items: [
        {
          id: "1",
          name: "Risk 1",
          residualRiskScore: 20,
          riskAppetiteScore: 17,
          timeline: "Q4 2023",
          description: "Finance Department",
          email: "mark.williams@example.com",
          createdBy: {
            name: "Mark Williams",
            avatarUrl:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
          },
        },
        {
          id: "2",
          name: "Risk 2",
          residualRiskScore: 16,
          riskAppetiteScore: 12,
          timeline: "Q1 2024",
          description: "HR Department",
          email: "suliyam.info@example.com",
          createdBy: {
            name: "Sulium Keliym",
            avatarUrl:
              "https://images.unsplash.com/photo-1485893086445-ed75865251e2?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
          },
        },
        {
          id: "3",
          name: "Risk 3",
          residualRiskScore: 12,
          riskAppetiteScore: 11,
          timeline: "Q4 2024",
          description: "HR Department",
          email: "alex.semuyel@example.com",
          createdBy: {
            name: "Alex Semuyel",
            avatarUrl:
              "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
          },
        },
        {
          id: "4",
          name: "Risk 4",
          residualRiskScore: 12,
          riskAppetiteScore: 9,
          timeline: "Q2 2024",
          description: "HR Department",
          email: "limition@example.com",
          createdBy: {
            name: "Humil Limition",
            avatarUrl:
              "https://images.unsplash.com/photo-1502767089025-6572583495e4?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
          },
        },
        {
          id: "5",
          name: "Risk 5",
          residualRiskScore: 9,
          riskAppetiteScore: 4,
          timeline: "Q1 2024",
          description: "HR Department",
          email: "limition@example.com",
          createdBy: {
            name: "Humil Limition",
            avatarUrl:
              "https://images.unsplash.com/photo-1502767089025-6572583495e4?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=60",
          },
        },
      ],
    },
  };

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      query: observable,
      config: observable,
      data: observable,
      periodOptions: observable,
      currentRiskScore: observable,
      actions: computed,
      setSearchText: action,
      consume: action,
      load: flow,
    });

    this.parentStore = parentStore;
  }
  get processing() {
    return this.loading;
  }
  get actions() {
    const list = [];

    list.push({});
    return list;
  }

  setSearchText(search) {
    this.query.search = search;
  }

  consume(data) {
    const index = this.data.findIndex((u) => u.id === data.id);
    if (index !== -1) {
      this.data.splice(index, 1, data);
      this.data = [...this.data];
    }
  }

  *load(query, options) {}
}
