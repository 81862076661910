import { makeObservable, observable, flow, computed } from "mobx";

import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { Add } from "@/components/icons/IconMap";

import BrowseDomainControlsSchema from "@/areas/controls/schemas/BrowseDomainControlsSchema";

export default class BrowseDomainControlsStore {
  loading = false;
  pagination = null;
  controls = [];

  constructor(parentStore) {
    makeObservable(this, {
      loading: observable,
      pagination: observable,
      controls: observable,
      actions: computed,
      load: flow,
    });

    this.parentStore = parentStore;
  }

  get actions() {
    const list = [];

    list.push({
      id: "control:addControl",
      label: " New control",
      onClick: () => this.parentStore.createControlStore.show(),
      icon: Add,
      location: "primary",
      processing: this.parentStore.createControlStore.processing,
      disabled: this.parentStore.createControlStore.processing,
    });

    return list;
  }

  consume(control) {
    const index = this.controls.findIndex((e) => e.id === control.id);
    if (index !== -1) {
      this.controls.splice(index, 1, control);
      this.controls = [...this.controls];
    }
  }

  *load(query, options) {
    const parseResult = BrowseDomainControlsSchema.safeParse(query);
    if (!parseResult.success) {
      return parseResult;
    }
    try {
      this.loading = true;
      const response = yield api.post(ApiRoutes.forControlSearch(), query);
      if (response.status == 200) {
        this.controls = response.data.items;
        this.pagination = {
          page: response.data.page,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          pageSize: response.data.pageSize,
        };
        return { success: true, data: response.data };
      }
    } catch (e) {
      return { success: false, error: e };
    } finally {
      this.loading = false;
    }
  }
}
