import { z } from "zod";

const CreateModelSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must be less than 400 characters." }),
    description: z.string().min(1, { message: "Description is required." }),
  })
  .strict();

export default CreateModelSchema;
