import React from "react";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const iconVariants = cva("animate-spin", {
  variants: {
    variant: {
      default: "text-foreground",
      "muted-foreground": "text-muted-foreground",
      primary: "text-primary",
      "primary-foreground": "text-primary-foreground",
      secondary: "text-secondary",
      "secondary-foreground": "text-secondary-foreground",
      destructive: "text-destructive",
      "destructive-foreground": "text-destructive-foreground",
    },
    size: {
      default: "h-7 w-7",
      sm: "h-6 w-6",
      xs: "h-4 w-4",
      lg: "h-10 w-10",
      xl: "h-20 w-20",
    },
  },
  defaultVariants: {
    size: "default",
    variant: "default",
  },
});

export const LoaderIcon = ({ className, variant, size }) => {
  return (
    <svg
      className={cn(iconVariants({ variant, size }), className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
