import { makeObservable, observable, flow, computed, action } from "mobx";

export class TenantBrowseStore {
    myItems = [];
    items = [];
    keywords = "";
    pageSize = 25;
    continuationToken = null;
    loading = false;
    error = null;

    constructor(tenantStore) {
        makeObservable(this, {
            myItems: observable,
            filteredMyItems: computed,
            items: observable,
            filteredItems: computed,
            keywords: observable,
            pageSize: observable,
            continuationToken: observable,
            loading: observable,
            error: observable,
            setKeywords: action,
            load: flow,
        });

        this.tenantStore = tenantStore;
        this.tenantService = tenantStore.tenantService;
        this.notificationStore = tenantStore.rootStore.notificationStore;
    }

    get filteredMyItems() {
        const k = (this.keywords || "").toLowerCase();
        return this.myItems.filter((x) => x.name.toLowerCase().indexOf(k) !== -1);
    }

    get filteredItems() {
        const k = (this.keywords || "").toLowerCase();
        return this.items.filter((x) => x.name.toLowerCase().indexOf(k) !== -1);
    }

    setKeywords(keywords) {
        this.keywords = keywords;
    }

    *load() {
        try {
            this.loading = true;
            const myResult = yield this.tenantService.getMyTenants({
                pageSize: this.pageSize,
                continuationToken: this.continuationToken,
            });
            this.myItems = myResult.items;

            const result = yield this.tenantService.getTenants({
                pageSize: this.pageSize,
                continuationToken: this.continuationToken,
            });

            const myIds = this.myItems.map((i) => i.id);
            this.items = result.items.filter((i) => myIds.indexOf(i.id) === -1);
            this.pageSize = result.pageSize;
            this.continuationToken = result.continuationToken;

            return result;
        } catch (e) {
            this.error = e;
            this.notificationStore.error({
                title: e.title,
                description: e.errors ? Object.values(e.errors).join("\r\n") : null,
            });
            throw e;
        } finally {
            this.loading = false;
        }
    }
}
