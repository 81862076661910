import { z } from "zod";

const parentSchema = z.object({
  id: z.string().array().nullable(),
});

const querySchema = z.object({
  parent: parentSchema,
});

const BrowseDomainSchema = z
  .object({
    search: z.string(),
    query: querySchema,
    page: z.number().min(1, { message: "Page is out of bounds" }),
  })
  .strict();

export default BrowseDomainSchema;
