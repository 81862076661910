import { makeObservable, observable, flow, action } from "mobx";
import { toast } from "sonner";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";

import InviteUsersSchema from "@/areas/directory/schemas/InviteUsersSchema";

export default class InviteUsersStore {
  visible = false;
  processing = false;
  invitations = [];
  results = {};

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      processing: observable,
      invitations: observable,
      results: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  show() {
    this.invitations = [];
    this.results = {};
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.results = {};
    this.invitations = [];
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    try {
      this.processing = true;

      const parseResult = InviteUsersSchema.safeParse(data);
      if (!parseResult.success) {
        return parseResult;
      }

      let success = 0;
      let errors = 0;
      const invitations = data.invitations;
      for (let i = 0; i < invitations.length; i++) {
        try {
          const response = yield api.post(ApiRoutes.forOrgUsers(), {
            foreignId: invitations[i].user.id,
            domainId: invitations[i].user.domainId,
            message: data.message,
            role: invitations[i].role,
          });
          success++;
          const update = { ...this.results };
          update[invitations[i].user.id] = { success: true };
          this.results = update;
        } catch (e) {
          errors++;
          console.error(e);
          const update = { ...this.results };
          update[invitations[i].user.id] = { success: false, error: e };
          this.results = update;
        }
      }

      this.visible = errors !== 0;
      toast.success(`${success} users invited`);
      return { success: errors === 0, data: this.results, errors };
    } catch (e) {
      toast.error("Error while inviting users", {
        description: "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.processing = false;
    }
  }
}
