import { z } from "zod";

const CreateDomainSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    shortCode: z.string().optional().nullable(),
    description: z.string().optional(),
    registryId: z.string().uuid({ message: "Registry identifier is required." }),
    parentId: z.string().uuid().optional().nullable(),
  })
  .strict();

export default CreateDomainSchema;
