import React from "react";

export function AnalyticsIcon({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="none"
      className={className}
    >
      <path
        d="M14 3a2 2 0 0 1 2 2v3h4a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h4V5a2 2 0 0 1 2-2zm0 2h-4v14h4zm6 5h-4v9h4zM8 13H4v6h4z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
      ></path>
    </svg>
  );
}
